$(function() {
  function animate() {
    // Frame 1
    setTimeout(function () {
      $(".active-shadow").each(function(index, element) {
        element.classList.remove("active-shadow");
      });
      $("#line-2, #line-4, #line-5, #line-7").each(function(index, element) {
        element.style.zIndex = 0;
        element.querySelector("path").setAttribute("stroke", "#CED7FF");
      });
      $("#inbound-number-1-bg, #time-conditions-bg, #voice-menu-bg, #queue-bg").each(function(index, element) {
        element.classList.add("active-shadow");
      });
      $("#line-1, #line-4, #line-5").each(function(index, element) {
        element.style.zIndex = 1;
        element.querySelector("path").setAttribute("stroke", "#3C5BF5");
      });
    }, 1);

    // Frame 2
    setTimeout(function () {
      // Remove classes and attributes from frame 1
      $(".active-shadow").each(function(index, element) {
        element.classList.remove("active-shadow");
      });
      $("#line-1, #line-4, #line-5").each(function(index, element) {
        element.style.zIndex = 0;
        element.querySelector("path").setAttribute("stroke", "#CED7FF");
      });

      // Apply changes for frame 2
      $("#inbound-number-2-bg, #time-conditions-bg, #voicemail-bg").each(function(index, element) {
        element.classList.add("active-shadow");
      });
      $("#line-2, #line-3").each(function(index, element) {
        element.style.zIndex = 1;
        element.querySelector("path").setAttribute("stroke", "#3C5BF5");
      });
    }, 1501);

    // Frame 3
    setTimeout(function () {
      // Remove classes and attributes from frame 2
      $(".active-shadow").each(function(index, element) {
        element.classList.remove("active-shadow");
      });
      $("#line-2, #line-3").each(function(index, element) {
        element.style.zIndex = 0;
        element.querySelector("path").setAttribute("stroke", "#CED7FF");
      });

      // Apply changes for frame 3
      $("#inbound-number-1-bg, #time-conditions-bg, #voice-menu-bg, #announcement-bg").each(function(index, element) {
        element.classList.add("active-shadow");
      });
      $("#line-1, #line-4, #line-6").each(function(index, element) {
        element.style.zIndex = 1;
        element.querySelector("path").setAttribute("stroke", "#3C5BF5");
      });
    }, 3001);

    // Frame 4
    setTimeout(function () {
      // Remove classes and attributes from frame 3
      $(".active-shadow").each(function(index, element) {
        element.classList.remove("active-shadow");
      });
      $("#line-1, #line-4, #line-6").each(function(index, element) {
        element.style.zIndex = 0;
        element.querySelector("path").setAttribute("stroke", "#CED7FF");
      });
      $("#arrow-1, #arrow-4, #arrow-6").each(function(index, element) {
        element.style.zIndex = 1;
        element.querySelector("path").setAttribute("stroke", "#3C5BF5");
      });

      // Apply changes for frame 4
      $("#inbound-number-2-bg, #time-conditions-bg, #voice-menu-bg, #queue-bg, #voicemail-bg").each(function(index, element) {
        element.classList.add("active-shadow");
      });
      $("#line-2, #line-4, #line-5, #line-7").each(function(index, element) {
        element.style.zIndex = 1;
        element.querySelector("path").setAttribute("stroke", "#3C5BF5");
      });
      $("#arrow-2, #arrow-4, #arrow-5, #arrow-7").each(function(index, element) {
        element.style.zIndex = 1;
        element.querySelector("path").setAttribute("stroke", "#3C5BF5");
      });
    }, 4501);

    // Recursive call for infinite animation
    setTimeout(animate, 6001);
  }

  // Start the animation
  animate();
});
